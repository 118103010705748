<template>
  <v-row>

      <DialogDashboards :dialog="dialog" />

    <v-dialog v-model="dialogDetalhePizza" scrollable>
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Detalhe Demonstrativo DRE</span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-0">
          <v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
            <template v-slot:default>
              <thead class="header">
              <tr>
                <th class="pr-0">Empresa</th>
                <th class="pr-0">Meta</th>
                <th class="pr-0">Conta</th>
                <th class="pr-0">Seção DRE</th>
                <th class="pr-0">Valor</th>
              </tr>
              </thead>
              <tbody class="body text-capitalize">
              <tr v-for="(d, i) in dadosDRE.lista" :key="i">
                <td nowrap>{{ d.empresa }}</td>
                <td>{{ d.meta | formataDinheiro }}</td>
                <td>{{ d.opcao }}</td>
                <td>{{ d.nomedre }}</td>
                <td>{{ d.valordre | formataDinheiro }}</td>
              </tr>
              </tbody>
            </template>

          </v-simple-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn :loading="carregando" class="px-5" color="primary" elevation="0"
                 @click="dialogDetalhePizza = false">Fechar</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SOMENTE FILTROS-->
      <v-col cols="12" md="12">
        <v-card>
          <v-card-text>
            <v-form>
              <v-row
              >
                <v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtinicio"
                      label="Data inicial"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col class="d-none d-md-block px-1">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtfim"
                      label="Data final"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>

                <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                  <v-sheet class="text-center" height="100%">
                    <v-row class="pa-3">
                      <v-col cols="6" class="px-2">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtinicio"
                            label="Data inicial"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-col cols="6" class="px-2">
                        <InputDatePicker
                            :disabled="carregando"
                            v-model="busca.dtfim"
                            label="Data final"
                            :outlined="true"
                            :dense="true"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn text class="mt-n3" color="primary" @click="listarMetaDRE(), sheet = !sheet">Filtrar</v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-bottom-sheet>
                <v-col cols="12" md="4" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
                  <v-autocomplete
                      class="d-inline-flex d-sm-none"
                      :items="filiaisFiltro"
                      clearable
                      item-text="filial"
                      item-value="idfilial"
                      label="Filial"
                      outlined
                      dense
                      :disabled="carregando"
                      v-model="busca.idfilial"
                      append-outer-icon="mdi-menu"
                      @click:append-outer="sheet = !sheet"
                      hide-details
                      required
                  ></v-autocomplete>
                  <v-autocomplete
                      class="d-none d-sm-block"
                      :items="filiaisFiltro"
                      clearable
                      item-text="filial"
                      item-value="idfilial"
                      label="Filial"
                      outlined
                      dense
                      :disabled="carregando"
                      v-model="busca.idfilial"
                      hide-details
                      required
                  ></v-autocomplete>
                  <v-btn
                      :disabled="carregando"
                      class="ml-2 mt-1"
                      color="primary"
                      elevation="0"
                      fab
                      x-small
                      @click="listarMetaDRE()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
          <v-card-actions class="px-4 my-0 py-0">
            <small
                v-if="autoRefresh && !carregando"
                class="text--secondary text-caption"
            >Próxima atualização em: {{tempoRefresh}} segundos</small>
            <v-spacer />
            <span class="d-flex mb-n3">
							<v-switch
                  :loading="carregando"
                  v-model="autoRefresh"
                  label="Atualização automática"
                  color="primary"
                  class="my-0 py-0"
              ></v-switch>
						</span>
          </v-card-actions>
        </v-card>
      </v-col>

    <!-- CARDS-->
      <v-col cols="12" md="12">
        <v-row class="pa-2">
          <v-col class="pa-1">
            <CardDashboard :loading="carregando" :title="`Meta do Mês`">
              <template v-if="!carregando">{{ dadosDRE.meta | formataDinheiro}}</template>
              <template v-else>0.00</template>
              <MenuInfo texto="Meta de venda do apoio a decisão" />
            </CardDashboard>
          </v-col>
          <v-col class="pa-1">
            <CardDashboard :loading="carregando" title="Atingimento">
              <template v-if="!carregando">{{ dadosDRE.ating | formataDinheiro }}</template>
              <template v-else>{{ 0 | formataDinheiro }}</template>
              <MenuInfo texto="Venda realizada de acordo com DRE" />
            </CardDashboard>
          </v-col>
          <v-col class="pa-1">
            <CardDashboard :loading="carregando" title="Meta Atingida (%)">
              <template v-if="!carregando">{{ dadosDRE.perc_ating }} %</template>
              <template v-else>0.00 %</template>
              <MenuInfo texto="% de atingimento da meta sobre a venda DRE" />
            </CardDashboard>
          </v-col>
        </v-row>
      </v-col>

      <!-- GRAFICOS -->
      <template v-if="dadosDRE">
        <v-col
            cols="12"
            v-if="dadosDRE.lista.length > 1"
            class="d-none d-md-block"
        >
          <v-card>
            <v-progress-linear indeterminate absolute :active="carregando" />
            <v-card-title>
              <span class="text-truncate">Comparativo Meta X DRE - Diário - {{formataMes(this.mesSelecionado)}}</span>
              <v-spacer />
              <!--
              <v-btn
                  :disabled="carregando"
                  class="px-2 mb-1 ml-auto mt-2 mr-2"
                  color="primary"
                  elevation="0"
                  small
                  @click="abrirDialog(
                                    dashboard.vendafilial,
									{
										descrfilial: {nome: 'Filial', tipo: 'texto'},
										valvenda: {nome: 'Venda realizada', tipo: 'dinheiro'},
										valmeta: {nome: 'Meta', tipo: 'dinheiro'},
										peratingimento: {nome: 'Atingido', tipo: 'porcentagem'},
										margem: {nome: 'Margem', tipo: 'porcentagem'},
									},
                                    'Venda realizada x Meta por filial'
                                )"
              >
                <v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
              </v-btn>
              -->
            </v-card-title>

            <v-card-text v-if="carregando">
              <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>

              <GraficoMultiLine
              :data-series="[
                  {
                    name: 'Venda DRE (dia)',
                    data: [0.12,0.15,0.23,2.6,5.0,7.5,8.8,8.9,10.31,11.3,14.6,15.9]
                  },
                  {
                    name: 'Despesas (dia)',
                    data: [0.1,0.5,1.0,1.55,2.39,5.78,7.43,9.47,13.4,18.2,19.4,21.3]
                  },
                  {
                    name: 'Meta (dia)',
                    data: [1,1,1,1,1,1,1,1,1,1,1,1]
                  },
                  {
                    name: 'Atingimento (dia)',
                    data: [2,5,1,6,4,2.3,7.1,3.4,4.1,2.4,3.7,6.9]
                  },

              ]"
              height="400"
              :categories="['01 Out', '02 Out', '03 Out', '04 Out', '05 Out', '06 Out', '07 Out', '08 Out', '09 Out', '10 Out', '11 Out', '12 Out']"
              :stroke="{ width: [5, 7, 5], curve: 'straight', dashArray: [0, 8, 5] }"

              />
            </v-card-text>
          </v-card>
        </v-col>
      </template>

      <template v-if="dadosDRE">
      <v-col
          cols="12"
          v-if="dadosDRE.lista.length > 1"
          class="d-none d-md-block"
      >
        <v-card>
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title>
            <span class="text-truncate">Comparativo Meta X DRE - Mensal - {{formataMes(this.mesSelecionado)}}</span>
            <v-spacer />
            <!--
            <v-btn
                :disabled="carregando"
                class="px-2 mb-1 ml-auto mt-2 mr-2"
                color="primary"
                elevation="0"
                small
                @click="abrirDialog(
                                  dashboard.vendafilial,
                {
                  descrfilial: {nome: 'Filial', tipo: 'texto'},
                  valvenda: {nome: 'Venda realizada', tipo: 'dinheiro'},
                  valmeta: {nome: 'Meta', tipo: 'dinheiro'},
                  peratingimento: {nome: 'Atingido', tipo: 'porcentagem'},
                  margem: {nome: 'Margem', tipo: 'porcentagem'},
                },
                                  'Venda realizada x Meta por filial'
                              )"
            >
              <v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
            </v-btn>
            -->
          </v-card-title>

          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>

            <GraficoBarraDRE

                :dataSeries="[dadosDRE.vendaliquida, dadosDRE.despesas, dadosDRE.meta, dadosDRE.ating]"
                :categories="[
                  ['Venda Líquida'],
                  ['Despesas'],
                  ['Meta'],
                  ['Atingimento Meta'],
                ]"
                :colors="['#002D56', '#951015', '#F2B90F', '#50A2E0']"
                :columnWidth="'45%'"
                :labels="['Venda Líquida','Despesas','Meta','Atingimento Meta']"
                :tooltipsY="[
								{ enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
								{ enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
								{ enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
								{ enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
							]"
                :money="true"

            />
          </v-card-text>
        </v-card>
      </v-col>
    </template>

    <!-- CARDS INFERIORES COM INFORMACOES COMPLEMENTARES -->
    <!-- CARD ESQUERDA -->
    <v-col cols="12" md="6" class="d-none d-md-block">
      <v-card>
        <v-progress-linear indeterminate absolute :active="carregando" />
        <v-card-title class="mb-n1">
          Demonstrativo DRE do Período
          <v-spacer />
          <v-btn
              :disabled="carregando"
              class="px-2 mb-1 ml-auto mt-2 mr-2"
              color="primary"
              elevation="0"
              small
              @click="dialogDetalhePizza = true"
          >
            <v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
          </v-btn>
        </v-card-title>
        <v-card-text v-if="carregando">
          <v-skeleton-loader class="mx-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else>
          <v-skeleton-loader elevation="2" v-if="carregando || !dadosDRE" class="mx-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
          <GraficoPizza
              v-else
              :key="JSON.stringify(dadosDRE.lista.valordre)"
              :dataSeries="dadosDRE.lista.map((v) => parseFloat(v.ating))"
              :labels="dadosDRE.lista.map((v) => v.nomedre)"
              :height="400"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <!-- CARD DIREITA -->
      <v-col cols="12" md="6" class="d-none d-md-block">
        <v-card>
          <v-progress-linear indeterminate absolute :active="carregando" />
          <v-card-title class="mb-n1">
            Demonstrativo de Despesas - A DEFINIR
            <v-spacer />
            <v-btn
                :disabled="carregando"
                class="px-2 mb-1 ml-auto mt-2 mr-2"
                color="primary"
                elevation="0"
                small
                @click="dialogDetalhePizza = true"
            >
              <v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
            </v-btn>
          </v-card-title>
          <v-card-text v-if="carregando">
            <v-skeleton-loader class="mx-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>
            <v-skeleton-loader elevation="2" v-if="carregando || !dadosDRE" class="mx-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
            <GraficoPizza
                v-else
                :key="JSON.stringify(dadosDRE.lista.valordre)"
                :dataSeries="dadosDRE.lista.map((v) => v.ating)"
                :labels="dadosDRE.lista.map((v) => v.nomedre)"
                :height="400"
            />
          </v-card-text>
        </v-card>
      </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import DialogDashboards from "../Widgets/DialogDashboards";
import GraficoMultiLine from "@/Components/Widgets/GraficoMultiLine.vue";
import GraficoPizza from "@/Components/Widgets/GraficoPizza.vue";
import GraficoBarraDRE from "@/Components/Widgets/GraficoBarraDRE.vue";
import mixinFilial from "@/util/mixinFilial";

export default {
  name: "DashboardVendas",
  mixins: [mixinFilial],
  components: {
    GraficoPizza,
    InputDatePicker,
    CardDashboard,
    MenuInfo,
    DialogDashboards,
    GraficoMultiLine,
    GraficoBarraDRE
  },
  data: () => ({
    tempoRefresh: 60,
    autoRefresh: false,
    filtroCompleto: [1, 4, 5,  8, 20],
    filtroComFilial: [1, 4, 5, 8, 20],
    Categorias:['Venda DRE', 'Meta', 'Despesas'],
    dadosTeste: [500000.00, 397562.00, 1200000.00],
    diasMesTeste: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
    mesSelecionado: "",
    sheet: false,
    dialog: { status: false, dados: [], header: {} },
    dialogDetalhePizza: false,
    carregando: false,
    filiais: [],
    regionais: [],
    dashboard: {},
    dadosDRE: {
      lista: [],
      meta:0,
      ating: 0,
      perc_ating: 0.0,
      despesas: 0,
      vendaliquida: 0,
    },
    dia: `${new Date().toLocaleDateString("pt-BR")}`,
    busca: {
      dtinicio: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
      idfiliais: "",
      idfilial: "",
    },
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.map((v) => {
          const filial = {};
          filial.idfilial = v.idfilial;
          filial.filial = `${v.idfilial} - ${v.filial}`;
          return filial;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    listarMetaDRE(){
      this.dadosDRE = {
        lista: [],
        meta:0,
        ating: 0,
        perc_ating: 0.0,
        despesas: 0,
        vendaliquida: 0,
      };
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}filial/meta/dre`, {
            dtinicio: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            idfilial: String(this.busca.idfilial) || null,
          })
          .then((res) => {
            this.dadosDRE.lista = res.data == null ? [] : res.data.map((v) => {
              if(v.opcao == 21){
                this.dadosDRE.meta = v.meta;
                this.dadosDRE.ating = v.valordre;
                this.dadosDRE.perc_ating = v.ating;
              }else if(v.opcao == 6){
                this.dadosDRE.despesas = v.valordre;
              }else if(v.opcao == 3){
                this.dadosDRE.vendaliquida = v.valordre;
              }
              return {
                ...v
            }
            });
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    timerRefresh() {
      if (this.tempoRefresh < 0) {
        this.tempoRefresh = 60;
      }
      setTimeout(() => {
        this.tempoRefresh--;
        this.timerRefresh();
      }, 1000);
    },
    abrirDialog(dados, header, title) {
      this.dialog.status = true;
      this.dialog.dados = dados;
      this.dialog.header = header;
      this.dialog.title = title;
    },
    capturaMes(){
      let mes = this.busca.dtfim;
      this.mesSelecionado = new Date(mes).getMonth() + 1;
    },
    formataMes(mes){
      switch (mes) {
        case 1:
          return 'Janeiro';
        case 2:
          return 'Fevereiro';
        case 3:
          return 'Março';
        case 4:
          return 'Abril';
        case 5:
          return 'Maio';
        case 6:
          return 'Junho';
        case 7:
          return 'Julho';
        case 8:
          return 'Agosto';
        case 9:
          return 'Setembro';
        case 10:
          return 'Outubro';
        case 11:
          return 'Novembro';
        case 12:
          return 'Dezembro';
      }
    },
    async init() {
      this.busca.idfilial = this.usuario.idfilial;
      await this.listarMetaDRE();
      await this.capturaMes();
      this.timerRefresh();
    },
  },
  watch: {
    "busca.dtfim": function () {
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtinicio = this.busca.dtfim;
      }
    },
    "busca.dtinicio": function () {
      if (this.busca.dtfim < this.busca.dtinicio) {
        this.busca.dtfim = this.busca.dtinicio;
      }
    },
    autoRefresh: function () {
      if (this.autoRefresh) {
        this.tempoRefresh = 60;
        this.get();
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
</style>