<template>
  <VueApexCharts
      type="line"
      :height="height ? height : 350"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoMultiLine",
  components: { VueApexCharts },
  props: [
    "dataSeries",
    "categories",
    "height",
    "width",
    "stroke",
    "title",
    "legenda",
    "markers",
    "tooltip",
    "grid"
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5],
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: (val, opts) => {
            return `${val} - <strong>${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}</strong>`;
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: (val) => val,
              },
            },
            {
              title: {
                formatter: (val) => val,
              },
            },
            {
              title: {
                formatter: (val) => val,
              },
            },
          ],
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      },
    };
  },
  created() {
    this.series = this.dataSeries;
    this.chartOptions.xaxis.categories = this.categories;
    this.chartOptions.colors = ["#002D56", "#951015", "#F2B90F", "#50A2E0"];

    if (this.stroke) {
      this.chartOptions.stroke = this.stroke;
    }
    if (this.title) {
      this.chartOptions.title.text = this.title.text;
      this.chartOptions.title.align = this.title.align;
    }
    if (this.markers) {
      this.chartOptions.markers = this.markers;
    }
    if (this.tooltip) {
      this.chartOptions.tooltip = this.tooltip;
    }
    if (this.grid) {
      this.chartOptions.grid = this.grid;
    }
  }
};
</script>
